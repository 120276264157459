import { queryOptions } from '@tanstack/react-query';
import {
  AuthSession,
  FetchUserAttributesOutput,
  GetCurrentUserOutput,
  fetchAuthSession,
  fetchUserAttributes,
  getCurrentUser,
} from 'aws-amplify/auth';

import { axiosApiV1Client } from './core';

export function queryKey() {
  return ['amplify', 'auth-session'];
}

export type AuthData = {
  user: GetCurrentUserOutput;
  attributes: FetchUserAttributesOutput;
  session: AuthSession;
};

export function authSessionQuery() {
  return queryOptions({
    queryKey: queryKey(),
    async queryFn(): Promise<AuthData | null> {
      // Do `getCurrentUser` first as it tests local storage and fails faster
      // than the Promise.all does.
      const user = await getCurrentUser();

      const [attributes, session] = await Promise.all([
        fetchUserAttributes(),
        fetchAuthSession(),
      ]);

      if (!session.tokens?.accessToken) {
        throw new Error('Session did not contain an access token');
      }

      axiosApiV1Client.defaults.headers.common['Authorization'] =
        `Bearer ${session.tokens.accessToken.toString()}`;

      return { user, attributes, session };
    },
  });
}
